import { gql } from '@apollo/client';

export const DELETE_THESIS_FAVORITE = gql`
  mutation DeleteFavoriteThesis($thesisID: uuid!, $userID: uuid!) {
    delete_user_favorite_thesis(
      where: { id_users: { _eq: $userID }, id_thesis: { _eq: $thesisID } }
    ) {
      affected_rows
    }
  }
`;

export default DELETE_THESIS_FAVORITE;
