import { anyof, isexact } from 'tiinvo';

export const togglefav = `togglefav`;
export const addtofavs = `addtofavs`;
export const contact = `contact`;
export const removefromfavs = `removefromfavs`;
export const view = `view`;

export type togglefav = typeof togglefav;
export type addtofavs = typeof addtofavs;
export type contact = typeof contact;
export type removefromfavs = typeof removefromfavs;
export type view = typeof view;

export type EntryAction = addtofavs | contact | removefromfavs | view;

//#region typeguards

export const istogglefav = isexact<togglefav>(togglefav);
export const isaddtofavs = isexact<addtofavs>(addtofavs);
export const iscontact = isexact<contact>(contact);
export const isremovefromfavs = isexact<removefromfavs>(removefromfavs);
export const isview = isexact<view>(view);
export const isEntryAction = anyof<EntryAction>(
  isaddtofavs,
  iscontact,
  isremovefromfavs,
  isview,
  istogglefav,
);

//#endregion
