import { Image } from '@chakra-ui/image';
import { AspectRatio, AspectRatioProps } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/react';
import { usePalette } from 'app/ui/usePalette';
import React, { FC } from 'react';
import { either, isstring, num, pass, pipe, str } from 'tiinvo';

export interface EntryImageProps extends AspectRatioProps {
  src?: string;
}

const issrcvalid = pipe(
  either.fromfunction(pass, isstring),
  either.mapRight(str.trim),
  either.mapRight(str.length),
  either.mapRight(num.greaterthan(0)),
  either.unwrapRightOr(false),
);

export const EntryImage: FC<EntryImageProps> = ({ src, ...otherprops }) => {
  const primary = usePalette(`primary`);
  const gradient = `linear(30deg, ${primary[200]}, ${primary[100]})`;
  const child = issrcvalid(src) ? (
    <Image objectFit="cover" src={src} />
  ) : (
    <Box bgGradient={gradient} />
  );

  return (
    <AspectRatio overflow="hidden" ratio={16 / 9} minWidth="56" {...otherprops}>
      {child}
    </AspectRatio>
  );
};
