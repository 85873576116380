import { HStack, StackProps } from '@chakra-ui/react';
import { AuthContext } from 'app/AuthProvider';
import { Reactions } from 'app/ui/reactions';
import Link from 'next/link';
import React, { FC, useContext } from 'react';
import * as TEntryAction from 'types/catalog/EntryAction';
import { EntryAction } from './EntryAction';
import { useFavouriteAction } from './hooks/useFavouriteAction';
export interface EntryActionsProps extends Omit<StackProps, 'onClick'> {
  canContact?: boolean;
  canFav?: boolean;
  canReact?: boolean;
  canView?: boolean;
  isfav?: boolean;
  entry: any;
}

export const EntryActions: FC<EntryActionsProps> = ({
  canContact = true,
  canFav = true,
  canReact = true,
  canView = true,
  isfav,
  entry,
  ...props
}) => {
  const handlefav = useFavouriteAction(entry);
  const { user } = useContext(AuthContext);

  return (
    <HStack spacing="2" {...props}>
      {canFav && (
        <EntryAction action={handlefav.actionname} onClick={handlefav.action} />
      )}
      {canContact && (
        <Link href={`/u/${entry.user.username}`} passHref>
          <a>
            <EntryAction action={TEntryAction.contact} />
          </a>
        </Link>
      )}
      {canView && (
        <Link href={`/thesis/${entry.slug}`} passHref>
          <a>
            <EntryAction action={TEntryAction.view} />
          </a>
        </Link>
      )}
      <Reactions
        isMyThesis={entry.user.id === user?.uuid}
        contentType="thesis"
        contentId={entry.id}
        userId={user?.uuid!}
      />
    </HStack>
  );
};
