import { useBreakpointValue, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import { FnBinary, pass } from 'tiinvo';
import { Thesis } from 'types/document/Thesis';
import { Entry } from './Entry';

export interface EntryListProps {
  entries?: Thesis[];
  onTagSelect?: FnBinary<string, number, any>;
  onThemeSelect?: FnBinary<string, number, any>;
}

export const EntryList: FC<EntryListProps> = ({
  entries = [],
  onTagSelect = pass,
  onThemeSelect = pass,
}) => {
  const spacing = useBreakpointValue<any>({
    base: `10`,
    md: `6`,
  });

  return (
    <VStack alignItems="flex-start" spacing={spacing}>
      {entries.map((entry, index) => (
        <Entry
          entry={entry}
          key={entry.id}
          onTagSelect={onTagSelect}
          onThemeSelect={onThemeSelect}
        />
      ))}
    </VStack>
  );
};
