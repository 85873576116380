import { Button, ButtonProps, useBreakpointValue } from '@chakra-ui/react';
import React, { FC } from 'react';
import {
  AiFillHeart,
  AiOutlineEye,
  AiOutlineHeart,
  AiOutlinePhone,
} from 'react-icons/ai';
import { defineMessages, useIntl } from 'react-intl';
import { bind, fallback, FnUnary, multi, pass } from 'tiinvo';
import * as TEntryAction from 'types/catalog/EntryAction';

export interface EntryActionProps extends Omit<ButtonProps, 'onClick'> {
  action: TEntryAction.EntryAction;
  onClick?: FnUnary<TEntryAction.EntryAction, any>;
}

//#region i18n

const messages = defineMessages({
  addtofavs: {
    id: `catalog.EntryAction.addtofavs`,
    defaultMessage: `Aggiungi ai preferiti`,
  },
  removefromfavs: {
    id: `catalog.EntryAction.removefromfavs`,
    defaultMessage: `Rimuovi dai preferiti`,
  },
  contact: {
    id: `catalog.EntryAction.contact`,
    defaultMessage: `Contatta`,
  },
  view: {
    id: `catalog.EntryAction.view`,
    defaultMessage: `Visualizza`,
  },
  none: {
    id: `catalog.EntryAction.none`,
    defaultMessage: ` `,
  },
});

//#endregion

const mapmessage = (type: TEntryAction.EntryAction) =>
  messages[type] ?? messages.none;

const mapicon = multi(
  fallback(fallback(null) as any),
  [TEntryAction.isaddtofavs, fallback(AiOutlineHeart)],
  [TEntryAction.isremovefromfavs, fallback(AiFillHeart)],
  [TEntryAction.iscontact, fallback(AiOutlinePhone)],
  [TEntryAction.isview, fallback(AiOutlineEye)],
);

export const EntryAction: FC<EntryActionProps> = ({
  action = TEntryAction.addtofavs,
  onClick = pass,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const Icon = mapicon(action);
  const gettext = formatMessage(mapmessage(action));
  const size = useBreakpointValue({
    base: `1.25rem`,
    sm: `1rem`,
  });
  const icon = <Icon size={size} />;
  const children = useBreakpointValue({
    base: icon,
    sm: gettext,
  });
  const leftIcon = useBreakpointValue<any>({
    base: undefined,
    sm: icon,
  });

  return (
    <Button
      {...props}
      _focus={{
        outline: 0,
      }}
      onClick={bind(onClick, action)}
      leftIcon={leftIcon}
      size="xs"
      variant="ghost"
    >
      {children}
    </Button>
  );
};
