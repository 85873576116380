import { Box, Divider, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { Paper } from 'app/ui/shapes/Paper';
import Link from 'next/link';
import React, { FC, useContext } from 'react';
import * as TThesis from 'types/document/Thesis';
import { AuthContext } from 'app/AuthProvider';
import { EntryProps } from './Entry.props';
import { EntryActions } from './EntryActions';
import { EntryImage } from './EntryImage';
import { EntryTags } from './EntryTags';
import { EntryThemes } from './EntryThemes';
import { censor } from '@utils/index';
import { useUserCompanyData } from '@hasura/hooks/useUserCompanyData';
import { UniversityDisplay } from 'app/ui/university';
import profile from 'pages/laura/my/profile';

export const EntryDesktop: FC<EntryProps> = ({
  entry,
  onThemeSelect,
  onTagSelect,
}) => {
  const { isLoggedIn } = useContext(AuthContext);
  const { companydata } = useUserCompanyData(
    entry.user.id,
    entry.user.user_type,
  );

  return (
    <Paper borderRadius="md" display="flex" overflow="hidden" width="full">
      <Link href={TThesis.mapthesisURL(entry)} passHref>
        <Box as="a" flex="0 0 auto">
          <EntryImage
            height="full"
            ratio={16 / 9}
            src={TThesis.mapcover_image_urlsafe(entry)}
          />
        </Box>
      </Link>

      <Box padding="4">
        <Heading fontWeight="900" margin="0" variant="h5">
          <Link href={TThesis.mapthesisURL(entry)}>{entry.title}</Link>
        </Heading>
        <Text marginTop="2" variant="t8">
          <Link href={TThesis.mapauthorURL(entry)}>
            {isLoggedIn ? (
              TThesis.mapauthor(entry)
            ) : (
              <span>
                {censor(entry?.user?.first_name ?? '')}{' '}
                {censor(entry?.user?.last_name ?? '')}{' '}
              </span>
            )}
          </Link>
          {companydata.business_name && (
            <span>
              {' - '}
              {companydata.business_name}
            </span>
          )}
          {entry.user.id_university && (
            <>
              {' - '}
              <UniversityDisplay universityId={entry.user.id_university} />
            </>
          )}
        </Text>
        <HStack spacing="2" marginY="2">
          <VStack alignItems="flex-start" spacing="2">
            <EntryThemes
              themes={(entry as any)?.thesis_themes?.reduce(
                (a: any, b: any) => [...a, b.themes],
                [],
              )}
              onClick={onThemeSelect}
            />
            <EntryTags
              onClick={onTagSelect}
              tags={(entry as any)?.thesis_tags?.reduce(
                (a: any, b: any) => [...a, b.tags],
                [],
              )}
            />
          </VStack>
          <Divider orientation="vertical" />
        </HStack>
        <Text display="block" flex="1 0 auto" fontStyle="italic" fontSize="xs">
          "{TThesis.mapsummary(entry)}"
        </Text>

        <EntryActions entry={entry} />
      </Box>
    </Paper>
  );
};
