import {
  AspectRatio,
  Box,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Paper } from 'app/ui/shapes/Paper';
import React, { FC, useContext } from 'react';
import { EntryProps } from './Entry.props';
import { EntryActions } from './EntryActions';
import { EntryTags } from './EntryTags';
import { EntryThemes } from './EntryThemes';
import * as TThesis from 'types/document/Thesis';
import { EntryImage } from './EntryImage';
import Link from 'next/link';
import { censor } from '@utils/index';
import { AuthContext } from 'app/AuthProvider';
import { useUserCompanyData } from '@hasura/hooks/useUserCompanyData';
import { UniversityDisplay } from 'app/ui/university';

export const EntryMobile: FC<EntryProps> = ({
  entry,
  onTagSelect,
  onThemeSelect,
}) => {
  const paddingx = '4';
  const { isLoggedIn } = useContext(AuthContext);
  const { companydata } = useUserCompanyData(
    entry.user.id,
    entry.user.user_type,
  );

  return (
    <Paper paddingTop="6" width="full">
      <Stack direction="column" spacing="2">
        <Heading fontWeight="black" margin="0" paddingX={paddingx} variant="h4">
          {TThesis.maptitle(entry)}
        </Heading>

        <Text paddingX={paddingx} variant="t8">
          {isLoggedIn ? (
            TThesis.mapauthor(entry)
          ) : (
            <span>
              {censor(entry?.user?.first_name ?? '')}{' '}
              {censor(entry?.user?.last_name ?? '')}{' '}
            </span>
          )}
          {companydata.business_name && (
            <span>
              {' - '}
              {companydata.business_name}
            </span>
          )}
          {entry.user.id_university && (
            <>
              {' - '}
              <UniversityDisplay universityId={entry.user.id_university} />
            </>
          )}
        </Text>

        <Box px={paddingx}>
          <EntryThemes
            themes={(entry as any)?.thesis_themes?.reduce(
              (a: any, b: any) => [...a, b.themes],
              [],
            )}
            onClick={onThemeSelect}
          />
          <EntryTags
            onClick={onTagSelect}
            tags={(entry as any)?.thesis_tags?.reduce(
              (a: any, b: any) => [...a, b.tags],
              [],
            )}
          />
        </Box>

        <Link href={TThesis.mapthesisURL(entry)}>
          <a>
            <EntryImage
              ratio={4 / 3}
              minWidth="56"
              src={TThesis.mapcover_image_urlsafe(entry)}
            />
          </a>
        </Link>
        <EntryActions
          justify="space-between"
          paddingY="2"
          paddingX={paddingx}
          entry={entry}
        />
        <Text fontSize="xs" paddingX={paddingx} paddingBottom="4">
          {TThesis.mapsummary(entry)}
        </Text>
      </Stack>
    </Paper>
  );
};
