import { gql, useQuery } from '@apollo/client';
import { UserRole } from 'types/auth/UserRole';
import { CompanyData } from 'types/company/CompanyData';

export interface GetCompanyDataByUserid {
  company_data: CompanyData[];
}

const GET_USER_COMPANY_DATA_BY_USER_ID = gql`
  query getCompanyDataByUserid($uuid: uuid!) {
    company_data(where: { user: { id: { _eq: $uuid } } }) {
      business_name
      description
      website
      city
      id
      id_business_detail
      id_user
      legal_entity
      business_detail {
        business_macro_sector
        id
      }
    }
  }
`;

export const useUserCompanyData = (userid: string, usertype: UserRole) => {
  const query = useQuery<GetCompanyDataByUserid>(
    GET_USER_COMPANY_DATA_BY_USER_ID,
    {
      variables: {
        uuid: userid,
      },
      skip: usertype !== 'usertype.company',
    },
  );

  const companydata = query.data?.company_data[0] ?? ({} as CompanyData);

  return { ...query, companydata };
};
