export interface Tag {
  id: string;
  name: string;
  created_at: string | null;
  updated_at: string | null;
}

export type t = Tag;

//#region mappables

export const mapid = (t: Tag) => t.id;
export const mapname = (t: Tag) => t.name;
export const mapcreated_at = (t: Tag) => t.created_at;
export const mapupdated_at = (t: Tag) => t.updated_at;

//#endregion
