import * as TApprovedByUser from './ApprovedByUser';
import * as TInsertByUser from './InsertByUser';

export interface Theme {
  approved_by_user: TApprovedByUser.ApprovedByUser;
  approved_by: string;
  approved: boolean;
  created_at: string;
  id: string;
  insert_by_user: TInsertByUser.InsertByUser;
  name: string;
  updated_at: string;
}

export type t = Theme;

//#region factories

export const create = (partial: Partial<Theme> = {}): Theme => ({
  approved_by_user: TApprovedByUser.create(partial.approved_by_user),
  approved_by: partial.approved_by ?? '',
  approved: partial.approved ?? false,
  created_at: partial.created_at ?? '',
  id: partial.id ?? '',
  insert_by_user: TInsertByUser.create(partial.insert_by_user),
  name: partial.name ?? '',
  updated_at: partial.updated_at ?? '',
});

//#endregion

//#region mappables

export const mapapproved = (theme: Theme) => theme.approved;
export const mapapproved_by = (theme: Theme) => theme.approved_by;
export const mapapproved_by_user = (theme: Theme) => theme?.approved_by_user;
export const mapcreated_at = (theme: Theme) => theme.created_at;
export const mapid = (theme: Theme) => theme.id;
export const mapinsert_by_user = (theme: Theme) => theme.insert_by_user;
export const mapname = (theme: Theme) => theme.name;
export const mapupdated_at = (theme: Theme) => theme.updated_at;

//#endregion
