import { HStack, StackProps, Text } from '@chakra-ui/react';
import { Theme } from '@typings/document/Theme';
import React, { FC } from 'react';
import { bind, FnBinary, pass } from 'tiinvo';

export interface EntryThemesProps extends Omit<StackProps, 'onClick'> {
  themes?: Theme[];
  onClick?: FnBinary<string, number, any>;
}

export const EntryThemes: FC<EntryThemesProps> = ({
  themes = [],
  onClick = pass,
  ...props
}) => {
  return (
    <HStack spacing="2" {...props}>
      {themes.map((item, index) => (
        <Text
          _hover={{ textDecoration: 'underline' }}
          cursor="pointer"
          key={index}
          margin="0"
          onClick={bind(onClick, item.id, index)}
          textDecor="underline"
          variant="t8"
        >
          {item.name}
        </Text>
      ))}
    </HStack>
  );
};
