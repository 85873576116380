import type { NodeEnv } from '@typings/Node';
import memoize from 'micro-memoize';
import mime from 'mime-db';

export const generateRandomString = (length: number = 8): string => {
  const alphabet =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_-';
  return [...Array(length)]
    .map((_) => alphabet[~~(Math.random() * alphabet.length)])
    .join('');
};

export const domainByEnv = () => {
  const env = process.env.NODE_ENV as NodeEnv;
  const baseDomains = {
    laura: 'e-laura.org',
    sofia: 'e-sofia.org',
  };

  switch (env) {
    case 'development':
      return {
        laura: `http://local.${baseDomains.laura}:3000`,
        sofia: `http://local.${baseDomains.sofia}:3000`,
      };
    case 'staging':
      return {
        laura: `http://staging.${baseDomains.laura}:3000`,
        sofia: `http://staging.${baseDomains.sofia}:3000`,
      };
    case 'production':
      return {
        laura: `https://${baseDomains.laura}`,
        sofia: `https://${baseDomains.sofia}`,
      };
  }
};

export const getMimeTypeExtension = memoize(
  (mimeType: string): string => mime[mimeType].extensions?.[0]!,
);

export function censor(txt: string): string {
  if (txt.length === 1) {
    return txt;
  }

  if (txt.length === 2) {
    return `${txt[0]}*`;
  }

  const initial = txt[0];
  const final = txt[txt.length - 1];
  const asterisks = Array.from({ length: txt.length - 2 })
    .map((_) => '*')
    .join('');

  return [initial, asterisks, final].join('');
}
