export interface InsertByUser {
  id: string;
  username: string;
  first_name: string;
  last_name: string;
}

//#region factories

export const create = (partial: Partial<InsertByUser> = {}): InsertByUser => ({
  id: partial.id ?? ``,
  username: partial.username ?? ``,
  first_name: partial.first_name ?? ``,
  last_name: partial.last_name ?? ``,
});

//#endregion
