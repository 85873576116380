import * as TUserProfile from 'types/auth/UserProfile';
import * as TTheme from 'types/document/Theme';
import { array, isstring, obj, option, pipe, str } from 'tiinvo';
import * as TReaction from './Reaction';
import { Tag } from './Tag';
import * as TThesisTag from './ThesisTag';

export interface Thesis {
  cover_image_url: string | null;
  created_at: string | null;
  id_professor: string;
  id_theme: string;
  id_user: string;
  id: string;
  pdf_url: string;
  tags?: TThesisTag.ThesisTag[];
  text: string;
  theme?: TTheme.Theme;
  thesis_tags: TThesisTag.ThesisTag[];
  thesis_themes: TTheme.Theme[];
  title: string;
  slug: string;
  updated_at: string | null;
  favorite?: boolean;
  user: TUserProfile.UserProfile;
}

//#region factories

export const create = (partial: Partial<Thesis> = {}): Thesis => ({
  cover_image_url: partial.cover_image_url ?? null,
  created_at: partial.created_at ?? null,
  id_professor: partial.id_professor ?? '',
  id_theme: partial.id_theme ?? '',
  id_user: partial.id_user ?? '',
  id: partial.id ?? '',
  pdf_url: partial.pdf_url ?? '',
  tags: partial.tags ?? [],
  theme: TTheme.create(partial.theme),
  thesis_tags: partial.thesis_tags ?? [],
  thesis_themes: partial.thesis_themes ?? [],
  text: partial.text ?? '',
  title: partial.title ?? '',
  slug: partial.slug ?? '',
  updated_at: partial.updated_at ?? null,
  user: TUserProfile.create(partial.user),
  favorite: partial.favorite ?? false,
});

//#endregion

//#region mappables

export const mapcover_image_url = (doc: Thesis) => doc.cover_image_url;
export const mapcreated_at = (doc: Thesis) => doc.created_at;
export const mapid_professor = (doc: Thesis) => doc.id_professor;
export const mapid_theme = (doc: Thesis) => doc.id_theme;
export const mapid_user = (doc: Thesis) => doc.id_user;
export const mapid = (doc: Thesis) => doc.id;
export const mappdf_url = (doc: Thesis) => doc.pdf_url;
export const mapslug = (doc: Thesis) => doc.slug;
export const maptags = (doc: Thesis) => doc.tags;
export const maptext = (doc: Thesis) => doc.text;
export const maptheme = (doc: Thesis) => doc.theme;
export const maptitle = (doc: Thesis) => doc.title;
export const mapupdated_at = (doc: Thesis) => doc.updated_at;
export const mapuser = (doc: Thesis) => doc.user;
export const mapfavorite = (doc: Thesis) => doc.favorite;
export const mapthesis_themes = (doc: Thesis) => doc.thesis_themes;

export const mapauthor = pipe(mapuser, TUserProfile.mapfullname);
export const mapthemename = pipe(
  option.fromfunction(maptheme),
  option.map(TTheme.mapname),
  option.unwrapOr(``),
);
export const mapcover_image_urlsafe = pipe(
  option.fromfunction(mapcover_image_url),
  option.unwrapOr(''),
);
export const mapcreated_atsafe = pipe(
  option.fromfunction(mapcreated_at),
  option.unwrapOr(undefined),
);
export const mapreactionssafe = pipe(
  option.map(array.map(obj.mapkey('reaction'))),
  option.unwrapOr<TReaction.Reaction[]>([]),
);
export const mapthesis_themesname = pipe(
  mapthesis_themes,
  array.map(TTheme.mapname),
);
export const maptagssafe = pipe(
  option.fromfunction(maptags),
  option.map(array.map(TThesisTag.mapname)),
  option.unwrapOr<string[]>([]),
);
export const mapupdated_atsafe = pipe(
  option.fromfunction(mapupdated_at),
  option.unwrapOr(undefined),
);
export const mapsummary = pipe(
  maptext,
  str.substring(0, 80),
  str.concat('...'),
);
export const mapauthorURL = pipe(mapuser, TUserProfile.mapProfileURL);
export const mapthesisURL = pipe(mapslug, (slug: string) => `/thesis/${slug}`);

//#endregion

//#region predicates

export const hascreatedate = pipe(mapcreated_at, isstring);
export const hasupdatedate = pipe(mapupdated_at, isstring);
export const hasimage = pipe(mapcover_image_url, isstring);

//#endregion
