import { gql } from '@apollo/client';

export const INSERT_THESIS_FAVORITE = gql`
  mutation InsertFavoriteThesis($thesisID: uuid!, $userID: uuid!) {
    insert_user_favorite_thesis_one(
      object: { id_thesis: $thesisID, id_users: $userID }
    ) {
      id
    }
  }
`;

export default INSERT_THESIS_FAVORITE;
