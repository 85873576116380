import { HStack, StackProps, Text } from '@chakra-ui/react';
import { Tag } from '@typings/document/Tag';
import React, { FC } from 'react';
import { array, bind, FnBinary, pass } from 'tiinvo';

export interface EntryTagsProps extends Omit<StackProps, 'onClick'> {
  tags?: Tag[];
  onClick?: FnBinary<string, number, any>;
}

const limit = array.takefirstnth(10);

export const EntryTags: FC<EntryTagsProps> = ({
  tags = [],
  onClick = pass,
  ...props
}) => {
  return (
    <HStack spacing="2" {...props}>
      {limit(tags).map((tag, index) => (
        <Text
          _hover={{ textDecoration: 'underline' }}
          cursor="pointer"
          fontWeight="black"
          key={index}
          onClick={bind(onClick, tag.id, index)}
          opacity=".54"
          variant="t8"
        >
          #{tag.name}
        </Text>
      ))}
    </HStack>
  );
};
