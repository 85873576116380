import { useMutation } from '@apollo/client';
import DELETE_THESIS_FAVORITE from '@hasura/queries/mutation/favorites/deleteThesisFavorite';
import INSERT_THESIS_FAVORITE from '@hasura/queries/mutation/favorites/insertThesisFavorite';
import { useAuth } from 'app/AuthProvider';
import { useEffect, useState } from 'react';
import { anyof, fallback, isdefined } from 'tiinvo';
import * as TEntryAction from 'types/catalog/EntryAction';
import { Thesis } from 'types/document/Thesis';

const shouldperformaction = anyof(
  TEntryAction.isaddtofavs,
  TEntryAction.isremovefromfavs,
);

export const useFavouriteAction = (entry: Thesis) => {
  const uuid = useAuth().user?.uuid;
  const canfav = isdefined(uuid);
  const [insertThesisFavorite] = useMutation(INSERT_THESIS_FAVORITE);
  const [deleteThesisFavorite] = useMutation(DELETE_THESIS_FAVORITE);
  const [docEntry, setDocEntry] = useState(entry);
  const [actionname, setactionname] = useState<TEntryAction.EntryAction>(
    entry.favorite ? TEntryAction.removefromfavs : TEntryAction.addtofavs,
  );

  useEffect(() => {
    setactionname(
      docEntry.favorite ? TEntryAction.removefromfavs : TEntryAction.addtofavs,
    );
  }, [docEntry.favorite]);

  if (!canfav) {
    return {
      action: fallback,
      actionname,
    };
  }

  const action = (action: TEntryAction.EntryAction) => {
    if (shouldperformaction(action)) {
      const oldEntry = { ...docEntry };
      const variables = {
        thesisID: entry.id,
        userID: uuid,
      };

      setDocEntry({
        ...oldEntry,
        favorite: !oldEntry.favorite,
      });

      if (oldEntry.favorite) {
        deleteThesisFavorite({ variables }).catch((e) => {
          console.error(e);
          setDocEntry(oldEntry);
        });
      } else {
        insertThesisFavorite({ variables }).catch((e) => {
          console.error(e);
          setDocEntry(oldEntry);
        });
      }
    }
  };

  return {
    action,
    actionname,
  };
};
