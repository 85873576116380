import { pipe } from 'tiinvo';
import * as TTag from './Tag';

export interface ThesisTag {
  id_tag: string;
  id: string;
  tag: TTag.Tag;
}

//#region mappables

export const mapid_tag = (t: ThesisTag) => t.id_tag;
export const mapid = (t: ThesisTag) => t.id;
export const maptag = (t: ThesisTag) => t.tag;

export const mapname = pipe(maptag, TTag.mapname);

//#endregion
